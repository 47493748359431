<template>
  <div class="smartSocialSecurity">
    <div class="banner" ref="banner">
      <img src="@/assets/img/smartSocialSecurity/banner.png" />
    </div>
    <div class="mainContent">
      <div class="side">
        <div class="tree">
          <div class="first" v-for="(v, k) in sideTreeList">
            <div class="title checked">
              <img :src="require(`@/assets/img/terminal/treeNode.svg`)" />
              <p>{{ v.label }}</p>
            </div>
            <ul>
              <li :class="checkIndex == i ? 'detailBg' : ''" v-for="(j, i) in v.children" @click="handleClick(j, i)">
                {{ j.label }}
              </li>
            </ul>
          </div>
        </div>
        <div class="line"></div>
      </div>
      <div class="right">
        <div class="details" ref="details">
          <div class="listItem" :ref="'item' + n" v-for="(m, n) in contentList">
            <div class="title">
              <!-- <img src="@/assets/img/about/about_title_icon.svg" /> -->
              <span>{{ m.index }}</span>
              <p>{{ m.label }}</p>
              <!-- <img src="@/assets/img/about/about_title_icon.svg" /> -->
            </div>
            <div class="text" v-html="m.description"></div>
            <div class="img" v-if="m.imgurl?.length">
              <img :src="img" v-for="img in m.imgurl" />
            </div>
            <div class="text" v-html="m.text"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { smartSocialSecurityList } from '@/assets/data/smartSocialSecurity'
export default {
  name: 'SmartSocialSecurity',
  components: {},
  computed: {
    sideTreeList() {
      return smartSocialSecurityList
    },
  },
  data() {
    return {
      checkIndex: 0,
      contentList: [],
    }
  },
  watch: {
    $route(to, from) {
      if (to.path === '/smartSocialSecurity') {
        this.checkIndex = this.sideTreeList[0].children.findIndex((v) => v.label == to.query.name)
        this.checkIndex = this.checkIndex === -1 ? 0 : this.checkIndex
        this.handleSlide(this.checkIndex)
      }
    },
  },
  methods: {
    handleSlide(index) {
      this.checkIndex = index
      const top = document.getElementsByClassName('listItem')[index].offsetTop
      window.scrollTo({
        top: top - 100,
        left: 0,
        behavior: 'smooth',
      })
    },
    handleClick(item, i) {
      this.handleSlide(i)
      // this.$router.push({
      //   path: '/smartSocialSecurity',
      //   query: {
      //     name: item.label,
      //   },
      // })
    },
  },
  created() {
    this.contentList = this.sideTreeList[0].children
  },
  mounted() {},
}
</script>
<style lang="less">
.smartSocialSecurity {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #fff;
  .banner {
    width: 100%;
    height: 3.9rem;
    position: relative;
    background: #eceaec;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .mainContent {
    padding: 0 3.57rem 0.6rem;
    display: flex;
    justify-content: space-between;
    .side {
      position: relative;
      .tree {
        z-index: 2;
        position: sticky;
        top: 1.78rem;
        margin-top: 1.08rem;
        .first {
          font-size: 0.18rem;
          color: #333333;
          margin-bottom: 0.4rem;
          .title {
            display: flex;
            cursor: pointer;
            align-items: center;
            img {
              width: 0.14rem;
              height: 0.14rem;
              margin-right: 0.07rem;
              z-index: 10;
            }
            p {
              word-break: keep-all;
            }
          }
          .checked {
            color: #00a4ea;
          }
          ul {
            padding-left: 0.17rem;
            li {
              padding-left: 0.13rem;
              font-size: 0.16rem;
              // width: 1.26rem;
              height: 0.3rem;
              line-height: 0.3rem;
              margin-top: 0.2rem;
              cursor: pointer;
              &:hover {
                color: #00a4ea;
              }
            }
            .detailBg {
              background-image: url('../assets/img/terminal/detailBg.svg');
              background-size: cover;
              color: #fff;
              &:hover {
                color: #fff;
              }
            }
          }
        }
      }
      .line {
        z-index: 1;
        width: 0.04rem;
        height: 2rem;
        background: #c9cdd4;
        border-radius: 0rem 0rem 0rem 0rem;
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0.05rem;
        height: 100%;
      }
    }
    .right {
      flex: 1;
      margin-left: 0.5rem;
      padding-top: 0.8rem;
      .details {
        .listItem {
          margin-bottom: 0.5rem;
          &:nth-child(1) {
            margin-top: 0;
          }
          .title {
            display: flex;
            align-items: center;
            height: 0.88rem;
            background: linear-gradient(180deg, #f6f7fb 0%, #f6f7fb 0%, #ffffff 100%);
            border-radius: 0.06rem 0.06rem 0 0;
            border-bottom: 0.01rem dashed #707070;
            span {
              font-size: 0.54rem;
              font-weight: 600;
              color: #333333;
              margin: 0 0.2rem;
              line-height: 0;
            }
            p {
              font-size: 0.28rem;
              font-weight: bold;
              color: #333333;
              line-height: 0.88rem;
              margin: 0 0.1rem;
            }
            img {
              width: 0.3rem;
              height: auto;
            }
          }
          .text {
            font-size: 0.18rem;
            font-weight: 400;
            color: #666;
            line-height: 0.3rem;
            margin-top: 0.2rem;
            margin-bottom: 0.2rem;
            p {
              // text-indent: 2em;
              line-height: 0.4rem;
            }
            b {
              // color: #00a4ea;
            }
            span {
              color: #ffffff;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              border-radius: 30px;
              font-size: 0.18rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            [index='1'] {
              font-size: 0.18rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              color: #333333;
              // background-image: url('../assets/img/smartSocialSecurity/title_1.svg');
            }
            // [index='2'] {
            //   background-image: url('../assets/img/smartSocialSecurity/title_2.svg');
            // }
            // [index='3'] {
            //   background-image: url('../assets/img/smartSocialSecurity/title_3.svg');
            // }
            // [index='4'] {
            //   background-image: url('../assets/img/smartSocialSecurity/title_4.svg');
            // }
          }
          .img {
            width: 100%;
            height: 2.41rem;
            padding: 0 0.3rem;
            text-align: center;
            img {
              width: 4.02rem;
              height: 2.41rem;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
</style>
